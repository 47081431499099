import AxiosService from "@/services/AxiosService";

class SubAccsService {
  getSubAccs(payload) {
    return AxiosService.get(`/subaccounts`, { params: payload })
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getSubAcc(payload) {
    return await AxiosService.get(`/subaccount/${payload}`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getFile() {
    return AxiosService.get("/subaccount/generate-file")
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async getDNS() {
    return AxiosService.get("/subaccount/generate-dns")
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async checkSite(payload) {
    return AxiosService.post("/subaccount/check-site", payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeFacebookRedirect(id, payload) {
    return AxiosService.patch(
      `/subaccount/${id}/facebook-web-view-redirect`,
      payload
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getLandings() {
    return AxiosService.get("/landings", {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async cloneSubAcc(payload) {
    return await AxiosService.post(`/subaccount/${payload}/clone`)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async trashSubAcc(payload) {
    return await AxiosService.patch(`/subaccount/${payload.id}/trash`, {
      trash: +!payload.trash,
    })
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async updateSubAcc(payload) {
    return await AxiosService.put(`/subaccount/${payload.id}`, payload.params)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async finishSubAcc(payload) {
    return await AxiosService.put(
      `/subaccount/${payload.id}?is_new=true`,
      payload.params
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async createSubAcc(payload) {
    return await AxiosService.post("/subaccount", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default new SubAccsService();
