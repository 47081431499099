<template>
  <div class="moderation-container">
    <div class="moderation-container__block">
      <img
        :src="
          !approve_status
            ? require('@/assets/images/icons/user-ban.svg')
            : require('@/assets/images/icons/wait.svg')
        "
      />

      <span class="moderation-container__title">{{ title }}</span>
      <span class="moderation-container__text">{{ reason }}</span>
      <div class="have-question" v-if="approve_status">
        <h4>{{ $t('have_questions') }}</h4>
        <div class="help-block">
          <button class="btn btn-support" @click="openLink('Leo31PH')">@Leo31PH</button>
          <button class="btn btn-support" @click="openLink('PH_support_Dmitriy')">@PH_support_Dmitriy</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Moderation",
  props: {
    title: String,
    reason: {
      type: String,
      default: "",
    },
    approve_status: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openLink(username){
      window.open(`https://t.me/${username}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.moderation-container {
  width: 100%;
  flex: 1;
  min-height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f9;
  border-radius: 15px;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: #000109;
    .have-question {
      margin-top: 20px;
      width: 600px;
      background: #fdfdfd;
      border-radius: 20px;
      padding: 40px;
      text-align: center;
      h4 {
        font-size: 20px;
        font-weight: 400;
      }
      .help-block {
        display: flex;
        justify-content: center;
        column-gap: 12px;
        margin-top: 30px;
        .btn-support {
          background: transparent;
          color: #5084B5;
          border: 1px solid #5084B5;
        }
      }
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
    font-size: 20px;      
    }
  }

  &__text {
    font-size: 18px;
    text-align: center;
    @media screen and (max-width: 768px) {
    font-size: 16px; 
    max-width: 55%;     
    }
  }
}
</style>